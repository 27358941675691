<script setup lang="ts">
//Element plus imports
import 'element-plus/dist/index.css'
import 'dayjs/locale/ru'
import ruRU from 'element-plus/dist/locale/ru.mjs'

useHead(() => ({
  htmlAttrs: {
    lang: 'ru',
  },
}))

useSeoMeta({
  title: 'FoxTailBox',
  titleTemplate: (name) =>
    name ? `${name} | Администрирование FoxTailBox` : 'Администрирование FoxTailBox',
})
</script>

<template>
  <div class="flex min-h-screen flex-col items-center">
    <el-config-provider :locale="ruRU">
      <Header />
      <div class="flex w-full max-w-[1366px] flex-grow pb-10 pt-16">
        <slot />
      </div>
    </el-config-provider>
  </div>
</template>

<style>
:root {
  --el-color-primary: #ffc43b;
}
</style>
